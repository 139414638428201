import React from "react";

const Promotion = () => {
  return (
    <React.Fragment>
      <div className="hidden md:block relative w-full bg-white">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-full h-auto"
          viewBox="0 0 1440 200"
          fill="none"
          preserveAspectRatio="none"
        >
          <path
            d="M0,200 C360,50 1080,50 1440,200 L1440,0 L0,0 Z"
            fill="#ECF2FB"
          />
        </svg>
      </div>
      <div className="flex items-center justify-center py-[30px] px-[50px] md:py-[60px] md:px-[60px] lg:py-[120px] lg:px-[133px]">
        {" "}
        {/* Removed md:h-screen */}
        <div
          className="relative w-[1000px] bg-gray-900 rounded-[24px]"
          style={{
            border: "4px solid transparent", // Ensures the border is visible
            borderRadius: "24px",
            background: `
        linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), 
        linear-gradient(90deg, #0060E5, #1A65E8, #476EEE, #5972F0, #7871D8, #B170AD, #DC6F8D, #F56E7A, #FF6E72)
      `,
            backgroundOrigin: "border-box",
            backgroundClip: "padding-box, border-box", // Gradient appears on the border
            aspectRatio: "16 / 9", // Maintains 16:9 aspect ratio
          }}
        >
          <iframe
            className="absolute top-0 left-0 w-full h-full rounded-[24px]"
            src="https://www.youtube.com/embed/1GrM_Vx1h3Q?autoplay=1&loop=1&mute=1&playlist=1GrM_Vx1h3Q"
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Promotion;
