import React from "react";

const Howtouse1 = ({title, steps}) => {
  return (
    <React.Fragment>
      {/* Black Background */}
      <div
        className="flex flex-col items-center gap-[80px] w-full py-[100px] px-[50px] md:py-[60px] md:px-[60px] lg:py-[180px] lg:px-[133px]"
        style={{
          background:
            "linear-gradient(0deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.80) 100%), linear-gradient(0deg, #00204D 0%, #00204D 100%), #F7F7F7",
        }}
      >
        {/* Header Container */}
        <div>
          <h1 className="text-center howtouse-text1 sm:inline">
           {title[0]}
          </h1>
          <h1 className="text-center howtouse-text2 sm:inline">{title[1]}</h1>
        </div>

        {/* Container */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-[40px] justify-center">
          {steps.map((step, index) => (
            <div key={index} className="flex flex-col items-center gap-[36px] shrink-0">
              {/* Image 1 */}
              <div
                className="flex p-[12px] items-center gap-[10px]"
                style={{
                  borderRadius: "100px",
                  background: "#E5F0FF",
                }}
              >
                <img src={step.img} alt={`Step ${index + 1}`} />
              </div>

              {/* Details Container */}
              <div className="flex flex-col items-center gap-[12px] self-stretch">
                {/* Header */}
                <div className="flex justify-center items-center gap-[8px] self-stretch">
                  <h1 className="howtouse-text3">{step.title}</h1>
                </div>
                <h1 className="howtouse-text4">{step.subtitle}</h1>
              </div>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Howtouse1;
