import React, { useState, useEffect } from "react";
import LanguageSelectComponent from "../../language-select-component/language-select-component";

const Navbar = ({ navbarItems }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [IsDropdownVisible, setIsDropdownVisible] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);

  // Unscrollable Navbar Menu Opened
  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isMenuOpen]);

  // Scroll to the bottom of the page
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };

  // Handle menu item click (Contact Us)
  const handleItemClick = (key) => {
    console.log(key);
    if (key === "contactUs") {
      console.log("CONTACT US Clicked");
      scrollToBottom();
      setIsMenuOpen(false);
    }
    if (key === "news") {
      console.log("News Clicked");
      scrollToBottom();
      setIsMenuOpen(false);
    }
  };

  const toggleDropdown = (key) => {
    setActiveDropdown((prevKey) => (prevKey === key ? null : key));
  };

  return (
    <div className={`px-[30px] md:px-[100px] h-[100px] flex items-center`}>
      {/* Logo */}
      <img
        style={{ height: "55px" }}
        src={`${process.env.PUBLIC_URL}/img/logo/neuinx.png`}
        alt="Logo"
      />

      {!isMenuOpen && (
        <div className="block md:hidden ml-auto">
          <button
            onClick={() => setIsMenuOpen(true)}
            className="text-white focus:outline-none"
          >
            <img
              className={`h-[24px]`}
              src={`${process.env.PUBLIC_URL}/img/icon/hamburger-menu.svg`}
              alt="Menu"
            />
          </button>
        </div>
      )}

      <div
        className={`hidden md:flex ml-auto text-white md:space-y-0 md:space-x-[48px] font-bold p-4`}
        style={{
          fontFamily: "Bebas Neue",
          letterSpacing: "0.2em",
          lineHeight: "1em",
          fontSize: "14px",
          alignItems: "center",
        }}
      >
        {Object.keys(navbarItems).map((key) => (
          <div
            key={key}
            className="relative  flex flex-col items-center"
            onMouseEnter={() =>
              key === "products" && setIsDropdownVisible(true)
            }
            onMouseLeave={() =>
              key === "products" && setIsDropdownVisible(false)
            }
          >
            <a
              href="#"
              key={key}
              className="block md:inline"
              onClick={() => handleItemClick(key)}
            >
              {navbarItems[key]}
            </a>

            {key === "products" && IsDropdownVisible && (
              <div
                style={{
                  paddingTop: "10px",
                  position: "absolute",
                  top: "100%",
                  zIndex: 10,
                }}
              >
                <a
                  href="inJOY"
                  className="block px-4 py-2 hover:bg-gray-700"
                  style={{ backgroundColor: "transparent" }}
                >
                  inJOY
                </a>
              </div>
            )}
          </div>
        ))}
        <a href="#" className="block md:inline">
          <LanguageSelectComponent />
        </a>
      </div>

      {/* Navbar Medium Screen Overlay */}
      {isMenuOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
          <div className="text-white text-center">
            <button
              onClick={() => setIsMenuOpen(false)}
              className="absolute top-5 right-5 text-white text-3xl"
            >
              &times;
            </button>
            <ul className="space-y-6 text-lg font-bold">
              {Object.keys(navbarItems).map((key) => (
                <li key={key}>
                  <a
                    href="#"
                    className="block"
                    onClick={() => {
                      if (key === "products") {
                        toggleDropdown("products");
                      } else {
                        handleItemClick(key);
                      }
                    }}
                  >
                    {navbarItems[key]}
                  </a>
                  {key === "products" && activeDropdown === "products" && (
                    <div className="mt-2">
                      <a
                        href="inJOY"
                        className="block px-4 py-2 hover:bg-gray-700"
                        style={{ backgroundColor: "#e0e0e0" }}
                      >
                        inJOY
                      </a>
                    </div>
                  )}
                </li>
              ))}
              <li className="relative">
                <LanguageSelectComponent setMenu={setIsMenuOpen} />
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
