import React from "react";
import { connect } from "react-redux";
import Footer from "../../components/NeuinX/Footer/footer";
import Banner from "../../components/InJoy/Banner/banner";
import Promotion from "../../components/InJoy/Promotion/promotion";
import { Translation } from "react-i18next";
import Navbar from "../../components/InJoy/Navbar/navbar";

import Feature1 from "../../components/InJoy/Feature1/feature1";
import Feature2 from "../../components/InJoy/Feature2/feature2";
import Howtouse1 from "../../components/InJoy/Howtouse1/howtouse1";
import Howtouse2 from "../../components/InJoy/Howtouse2/howtouse2";
import Testimonials from "../../components/InJoy/Testimonials/testimonials";
import Faq from "../../components/InJoy/Faq/faq";
import Cta from "../../components/InJoy/Cta/cta";
import PricingComponent from "../../components/InJoy/pricing/pricing-component";
import Pricing from "../../components/InJoy/pricing/pricing";

class Component extends React.Component {
  render() {
    return (
      <Translation>
        {(t, { i18n }) => (
          <React.Fragment>
            {/* TODO: Navbar and Banner Section */}
            <div className={`bg-[#ECF2FB] w-full min-h-screen flex flex-col`}>
              <Navbar />
              <Banner title1={t("injoy.title-1")} title2={t("injoy.title-2")} title3={t("injoy.title-3")} slogan= {t("injoy.slogan")} />
            </div>
            {/* TODO: Video Section */}
            <Promotion />
            <Feature1 
              title1={t("injoy.feature-1-title-1")}
              title2={t("injoy.feature-1-title-2")}
              title3={t("injoy.feature-1-title-3")}
              header={t("injoy.feature-1-header")}
              subtitle={t("injoy.feature-1-subtitle")}
              subsubtitle={t("injoy.feature-1-subsubtitle")}
              bullet1={t("injoy.feature-1-bullet-1")}
              bullet2={t("injoy.feature-1-bullet-2")}
              bullet3={t("injoy.feature-1-bullet-3")}
              card1Title={t("injoy.feature-1-card-1-title")}
              card1Text={t("injoy.feature-1-card-1-text")}
              card2Title={t("injoy.feature-1-card-2-title")}
              card2Text={t("injoy.feature-1-card-2-text")}
              card3Title={t("injoy.feature-1-card-3-title")}
              card3Text={t("injoy.feature-1-card-3-text")}/>
            <Feature2 
              title1={[t("injoy.feature-2-title-1.line1"), t("injoy.feature-2-title-1.line2")]}
              highlightPoint={t("injoy.feature-2-highlight-point")}
              cardTitle={t("injoy.feature-2-card-title")}
              cardText={t("injoy.feature-2-card-text")}
              title2={t("injoy.feature-2-title-2")} 
              categories={[
                { 
                  "image": `${process.env.PUBLIC_URL}/img/injoy/feature2/2pts.svg`,
                  "text":t("injoy.feature-2-categories.2PTS")
                },
                { 
                  "image": `${process.env.PUBLIC_URL}/img/injoy/feature2/3pts.svg`,
                  "text":t("injoy.feature-2-categories.3PTS")
                },
                { 
                  "image": `${process.env.PUBLIC_URL}/img/injoy/feature2/REB.svg`,
                  "text":t("injoy.feature-2-categories.REB")
                },
                { 
                  "image": `${process.env.PUBLIC_URL}/img/injoy/feature2/STL.svg`,
                  "text":t("injoy.feature-2-categories.STL")
                },
                { 
                  "image": `${process.env.PUBLIC_URL}/img/injoy/feature2/AST.svg`,
                  "text":t("injoy.feature-2-categories.AST")
                },
              ]}
            />
            <Howtouse1 title={[t("injoy.howToUse1.title-1"),t("injoy.howToUse1.title-2")]}
              steps={[
                {
                  "img": `${process.env.PUBLIC_URL}/img/injoy/howToUse1/user-plus.svg`,
                  "title": t("injoy.howToUse1.step-1.title"),
                  "subtitle": t("injoy.howToUse1.step-1.subtitle")
                },
                {
                  "img": `${process.env.PUBLIC_URL}/img/injoy/howToUse1/film.svg`,
                  "title": t("injoy.howToUse1.step-2.title"),
                  "subtitle": t("injoy.howToUse1.step-2.subtitle")
                },
                {
                  "img": `${process.env.PUBLIC_URL}/img/injoy/howToUse1/highlight.svg`,
                  "title": t("injoy.howToUse1.step-3.title"),
                  "subtitle": t("injoy.howToUse1.step-3.subtitle")
                },
              ]}/>
            <Howtouse2 
              title={t("injoy.howToUse2.title")}
              cards={[
                {
                  "img": `${process.env.PUBLIC_URL}/img/injoy/howToUse2/box1.jpg`,
                  "user": t("injoy.howToUse2.card-1.user"),
                  "text": t("injoy.howToUse2.card-1.text")
                },
                {
                  "img": `${process.env.PUBLIC_URL}/img/injoy/howToUse2/box2.jpg`,
                  "user": t("injoy.howToUse2.card-2.user"),
                  "text": t("injoy.howToUse2.card-2.text")
                },
                {
                  "img": `${process.env.PUBLIC_URL}/img/injoy/howToUse2/box3.jpg`,
                  "user": t("injoy.howToUse2.card-3.user"),
                  "text": t("injoy.howToUse2.card-3.text")
                },
                {
                  "img": `${process.env.PUBLIC_URL}/img/injoy/howToUse2/box4.jpg`,
                  "user": t("injoy.howToUse2.card-4.user"),
                  "text": t("injoy.howToUse2.card-4.text")
                }
              ]}/>
            <Testimonials
              title={t("injoy.testimonials.title")}
              />
            <Pricing pricing={[
              {
                "title": t("injoy.pricing.1.title"),
                "price": t("injoy.pricing.1.price"),
                "detail": t("injoy.pricing.1.detail"),
              },
              {
                "title": t("injoy.pricing.2.title"),
                "price": t("injoy.pricing.2.price"),
                "detail": t("injoy.pricing.2.detail"),
              },
              {
                "title": t("injoy.pricing.3.title"),
                "price": t("injoy.pricing.3.price"),
                "detail": t("injoy.pricing.3.detail"),
              }
            ]}/>
            <Faq faq={[
              {
                "q": t("injoy.faq.q1"),
                "a": t("injoy.faq.a1")
              },
              {
                "q": t("injoy.faq.q2"),
                "a": t("injoy.faq.a2")
              },
              {
                "q": t("injoy.faq.q3"),
                "a": t("injoy.faq.a3")
              }
            ]}/>
            <Cta title={t("injoy.cta.title")}/>
            <Footer
              logo={`${process.env.PUBLIC_URL}/img/logo/injoy_logo_white.svg`}
            />
          </React.Fragment>
        )}
      </Translation>
    );
  }
}

const Redux = connect((store) => ({}))(Component);

export const InjoyPage = Redux;
