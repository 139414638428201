import React from "react";
import rectangle from "./recta.png";

const Cta = ({title}) => {
  return (
    <React.Fragment>
      {/* Container Background */}
      <div
        className="flex flex-col items-center gap-[40px] w-full py-[60px] px-[60px] lg:py-[120px] lg:px-[133px]"
        style={{
          background:
            "linear-gradient(0deg, rgba(0, 4, 10, 0.80) 0%, rgba(0, 4, 10, 0.80) 100%), linear-gradient(80deg, #FF6E72 1.04%, #F56E7A 9.43%, #DC6F8D 23.5%, #B170AD 41.51%, #7871D8 62.53%, #5972F0 72.79%, #476EEE 80.12%, #1A65E8 94.68%, #0060E5 102.16%)",
        }}
      >
        {/* Vector */}
        <div
          className="w-[200px] h-[100px] sm:w-[300px] sm:h-[200px] "
          style={{
            background: `url(${rectangle}) 100% 100% no-repeat`,
            backgroundColor: "transparent",
            backgroundSize: "contain",
          }}
        ></div>
        {/* Header */}
        <h1 className="testimonials-text1 text-white">
          {title}
        </h1>
        {/* Buttons */}
        {/* <div className="inline-flex max-h-[44px] md:p-[0px_30px] justify-center items-center shrink-0">
          <div
            className="flex p-[4px] items-center"
            style={{
              borderRadius: " 100px",
              background: " #FFF",
            }}
          >
            <button className="feature1-button">
              <h1 className="feature1-label">填寫聯絡信箱</h1>
            </button>
            <button className="feature1-button-active">
              <h1 className="feature1-label-active">即時獲得最新消息</h1>
            </button>
          </div>
        </div> */}
        {/* Subheader */}
        {/* <h1
          className="testimonials-text1 text-white"
          style={{
            fontSize: "16px",
            fontWeight: 400,
          }}
        >
          加入 inJOY 用戶社區，及時把握 AI 籃球技術免費體驗優惠
        </h1> */}
      </div>
    </React.Fragment>
  );
};

export default Cta;
