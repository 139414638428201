import React from "react";
import box1photo from "./box1photo.jpg";
import box2photo from "./box2photo.jpg";
import box3photo from "./box3photo.jpg";
import box4photo from "./box4photo.jpg";

const Howtouse2 = ({title, cards}) => {
  return (
    <React.Fragment>
      {/* Container White Background */}
      <div
        className="flex flex-col items-center gap-[50px] w-full py-[60px] md:px-[60px] lg:py-[120px] lg:px-[133px]"
        style={{
          background: "#FFF",
        }}
      >
        {/* Header */}
        <h1 className="howtouse2-text1">{title}</h1>
        {/* 4 Boxes Container */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 place-items-center w-full gap-y-8">
        {cards.map((card) => (
          <div
            key={card.user}
            className="flex p-[13px] w-[195px] h-[275px] flex-col justify-end items-center gap-[14px] shrink-0"
            style={{
              borderRadius: "12px",
              border: "2px solid var(--InJOY-Gradient, #0060E5)",
              background: `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.60) 100%), url(${card.img}) lightgray -163.116px 0px / 223.94% 104.25% no-repeat`,
            }}
          >
            {/* Blue Button */}
            <div
              className="flex p-[8px_16px] justify-center items-center gap-[8px]"
              style={{
                borderRadius: "100px",
                background: "var(--Main, #0060E5)",
              }}
            >
              <h1 className="howtouse2-text2">{card.user}</h1>
            </div>
            {/* Description */}
            <h1
              className="howtouse2-text2"
              style={{
                fontWeight: 400,
              }}
            >
              {card.text}
            </h1>
          </div>
        ))}
      </div>

      </div>
    </React.Fragment>
  );
};

export default Howtouse2;
