import React from "react";

const Banner = ({title1, title2, title3, slogan}) => {
  return (
    <React.Fragment>
      <div className="flex-grow px-0 ">
        <div
          className="bg-center bg-cover flex flex-col px-[50px] md:px-[60px] lg:px-[133px] w-full min-h-screen"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/img/background/injoy-banner-background.svg)`,
          }}
        >
          {/* TODO: Add banner here */}
          <div
            className={`flex flex-col lg:flex-row pt-[62px] pb-[60px] md:pb-0 md:pt-[90px] items-center gap-[100px] min-h-[80vh]`}
          >
            <div className={`w-full md:w-[581px]`}>
              <div className={`font-black leading-relaxed ${localStorage.getItem("i18nextLng") || "en" ? "text-[45px] leading-tight": "text-[58px]"}`}>
                {title1}
                <span
                  className={`bg-gradient-to-r from-[#B170AD] to-[#FF6E72] bg-clip-text text-transparent`}
                >
                  {title2}
                </span>
                <span
                  className={`bg-gradient-to-r from-[#0060E5] via-[#1A65E8] to-[#FF6E72] bg-clip-text text-transparent`}
                >
                  {title3}
                </span>
              </div>
              <div
                className={`text-gray-600 text-[18px] pt-[24px]`}
              >{slogan}</div>
            </div>
            <div className={"relative flex-grow"}>
              <img
                className={`w-[100%] md:w-[70%] md:mx-[15%]`}
                src={`${process.env.PUBLIC_URL}/img/background/banner_circle.svg`}
                alt="Banner Background"
              />
              <img
                className="w-[38%] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[2] md:w-[30%]"
                src={`${process.env.PUBLIC_URL}/img/banner/home.png`}
                alt="InJOY Demo"
              />

              {/* Adjusted Banner Ball */}
              <img
                className="w-[10%] absolute top-[70%] left-1/2 transform -translate-x-[-150%] -translate-y-[230%] md:w-[8%] md:-translate-x-[-140%] md:-translate-y-[190%]"
                src={`${process.env.PUBLIC_URL}/img/background/banner_ball.svg`}
                alt="Banner Ball"
              />

              {/* Banner Top Logo */}
              <img
                className="w-[55%] absolute top-[40%] left-1/2 transform -translate-x-[10%] -translate-y-[144%] z-[3] md:w-[36%] md:-translate-y-[165%]"
                src={`${process.env.PUBLIC_URL}/img/background/banner_top_logo.svg`}
                alt="Banner Top Logo"
              />
              <img
                className="w-[30%] absolute top-[45%] left-1/2 transform -translate-x-[-61%] -translate-y-[230%] md:-translate-y-[280%] md:-translate-x-[-60%] md:w-[20%]"
                src={`${process.env.PUBLIC_URL}/img/background/banner_white_line.svg`}
              />

              <img
                className="w-[32%] absolute top-[45%] left-1/2 transform -translate-x-[150%] -translate-y-[-80%] z-[1] md:w-[28%] md:-translate-y-[-50%]"
                src={`${process.env.PUBLIC_URL}/img/background/banner_curly_gradient.svg`}
              />
              <img
                className="w-[32%] absolute top-[45%] left-1/2 transform -translate-x-[145%] -translate-y-[-85%] md:w-[28%] md:-translate-y-[-55%]"
                src={`${process.env.PUBLIC_URL}/img/background/banner_curly_white.svg`}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Banner;
