import React from "react";

import feature2Photo from "./feature2_photo.png";

const Feature2 = ({title1, highlightPoint, cardTitle, cardText, title2, categories}) => {
  return (
    <React.Fragment>
      {/* Background */}
      <div
        className="flex flex-col items-center gap-[80px] w-full py-[30px] px-[50px] md:py-[60px] md:px-[60px] lg:py-[120px] lg:px-[133px]"
        style={{
          background: "#FFF0F0",
        }}
      >
        {/* Header */}
        <div>
          <h1 className="inline feature2-text1">{title1[0]}</h1>
          <h1 className="inline feature2-text2">{title1[1]}</h1>
        </div>
        {/* Pink Box */} 
        <div
          className="grid grid-cols-1 md:grid-cols-2 items-center gap-[40px] p-[20px] relative w-full min-h-[600px]"
          style={{
            borderRadius: "28px",
            background: "#FCC4C6",
          }}
        >
          {/* Text Container */}
          <div className="flex flex-col justify-center px-4">
            {/* Rounded Text & Subheader Container */}
            <div className="flex flex-col gap-[12px]">
              {/* Rounded Text */}
              <div
                className="flex w-full max-w-[300px] px-4 py-1 justify-center items-center"
                style={{
                  borderRadius: "20px",
                  background: "#FFF",
                }}
              >
                <h1 className="feature2-rounded-text text-sm md:text-base text-center leading-tight">
                  {highlightPoint}
                </h1>
              </div>
              <h1 className="feature2-text1 text-left text-[28px] md:text-[36px] font-bold">
                {cardTitle}
              </h1>
            </div>
            <p className="feature2-text1 text-left text-[16px] md:text-[18px] font-normal">
              {cardText}
            </p>
          </div>
          {/* Image */}
          <img src={feature2Photo} className="w-full max-w-[500px] mx-auto rounded-lg" />
        </div>
        {/* Container */}
        <div className="flex flex-col justify-center items-center gap-[40px] self-stretch">
          {/* Subheading 2 */}
          <h1
            className="self-stretch feature2-text1 text-left sm:text-center"
            style={{
              fontSize: "28px",
            }}
          >
            {title2}
          </h1>
          {/* Boxes Container */}
          <div className="grid grid-cols-1 md:grid-cols-5 gap-4 w-full place-items-center">
            {categories.map((card, index) => (
              <div
                key={index}
                className="flex flex-col items-center justify-center aspect-square w-full max-w-[200px] p-4 "
                style={{
                  borderRadius: "17px",
                  background: "#FFF",
                }}
              >
                <img src={card.image} className="w-12 h-12 object-contain" alt={card.text} />
                <h1 className="feature2-text1 text-[20px] text-center">{card.text}</h1>
              </div>
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Feature2;
