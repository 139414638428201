import React, { useState } from "react";
import LanguageSelectComponent from "../../language-select-component/language-select-component";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <React.Fragment>
      <div
        className={`px-[15px] md:px-[30px] lg:px-[133px] flex items-center justify-between h-[80px]`}
      >
        {/* Logo */}
        <img
          className={`w-[204px]`}
          src={`${process.env.PUBLIC_URL}/img/logo/injoy_logo_black.svg`}
        />
        {/* Navbar */}
        <div
          className="flex p-[10px] items-center gap-[10px] block md:hidden ml-auto"
          style={{
            borderRadius: "38px",
            background: "var(--Main, #0060E5)",
          }}
        >
          <button
            className="text-white focus:outline-none"
            onClick={() => setIsMenuOpen(true)}
          >
            <img
              className={`h-[24px]`}
              src={`${process.env.PUBLIC_URL}/img/icon/hamburger-menu.svg`}
              alt="Menu"
            />
          </button>
        </div>
        {/* Login */}
        <div className="hidden md:flex gap-[20px]">
          {/* Button 1 */}
          {/* <button
            className="flex w-[120px] p-[12px_20px] justify-center items-center gap-[10px] shrink-0"
            style={{
              borderRadius: "100px",
              background: "var(--Color, #FFF)",
            }}
          >
            <h1 className="navbar-text1">註冊</h1>
          </button> */}
          {/* Button 2 */}
          <button
            onClick={() => window.location.href = "https://injoy.neuinx.com"}
            className="flex w-[120px] p-[12px_20px] justify-center items-center gap-[10px] shrink-0"
            style={{
              borderRadius: "100px",
              background: "var(--Main, #0060E5)",
            }}
          >
            <h1 className="navbar-text1 text-white">Try Now</h1>
          </button>
          <a className="flex items-center justify-center">
            <LanguageSelectComponent />
          </a>
        </div>
      </div>
      {/* Fullscreen Overlay (Mobile Menu) */}
      {isMenuOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
          <div className="text-white text-center z-60">
            <button
              onClick={() => setIsMenuOpen(false)}
              className="absolute top-5 right-5 text-white text-3xl"
            >
              &times;
            </button>
            <ul className="space-y-6 text-lg font-bold">
              <li onClick={() => window.location.href = "https://injoy.neuinx.com"}>Try Now</li>
              <li className="relative">
                <LanguageSelectComponent setMenu={setIsMenuOpen} />
              </li>
            </ul>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Navbar;
