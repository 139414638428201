
import "./pricing.css";

const PricingComponent  = ({title="free", price="200", infos}) =>{
    return (
        <div className="pricing-card">
          <p className="pricing-header text-center">{title}</p>
          <div className="price-container">
            <p className={"toggle-monthly "}>
              {" "}
              <span>$</span>
              {price}
              <span style={{fontSize: "12px"}}>NTD</span>
            </p>
          </div>
          <ul className="text-center font-bold">
            <li className="py-[20px]">
                {infos}
            </li>
          </ul>
        </div>
      );
}


export default PricingComponent;
