import React, { useEffect, useState } from "react";
import i18next from "i18next";

const languageMap = {
  en: { label: "ENGLISH", dir: "ltr", active: true },
  zh: { label: "中文", dir: "ltr", active: false },
};

const LanguageSelectComponent = ({ setMenu }) => {
  const [selectedLanguage, setSelectedLanguage] = React.useState(
    localStorage.getItem("i18nextLng") || "zh"
  );
  const [isDropdownVisible, setIsDropdownVisible] = React.useState(false);

  // React.useEffect(() => {
  //   document.body.dir = languageMap[selectedLanguage].dir;
  //   localStorage.setItem("i18nextLng", selectedLanguage);
  //   i18next.changeLanguage(selectedLanguage); // Update i18next
  // }, [selectedLanguage]);

  const handleLanguageChange = (key) => {
    console.log(localStorage.getItem("i18nextLng"))
    console.log(`Changing language to: ${key}`);
    setSelectedLanguage(key);
    i18next.changeLanguage(key);
    document.body.dir = languageMap[key].dir;
    localStorage.setItem("i18nextLng", key);

    setIsDropdownVisible(false); // Close dropdown after selection

    if (setMenu) {
      setMenu(false); // Close Navbar overlay
    } else {
    }
  };

  return (
    <div
      style={{
        position: "relative",
        display: "inline-block",
        fontFamily: "Bebas Neue",
        letterSpacing: "0.2em",
        fontSize: "14px",
      }}
      // onMouseEnter={() => setIsDropdownVisible(true)}
      // onMouseLeave={() => setIsDropdownVisible(false)}
      onClick={(e) => {
        e.stopPropagation();
        setIsDropdownVisible((prev) => !prev);
      }}
    >
      <div
        style={{
          cursor: "pointer",
          padding: "0px 10px",
        }}
      >
        {languageMap[selectedLanguage].label}
      </div>
      {isDropdownVisible && (
        <div
          style={{
            // paddingTop: "10px",
            // position: "absolute",
            // top: "100%",
            // left: "0",
            // zIndex: 100,
            paddingTop: "10px",
            position: "absolute",
            top: "100%",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 100,
          }}
          onClick={(e) => e.stopPropagation()}
        >
          {Object.keys(languageMap).map((key) => (
            <div
              key={key}
              style={{
                padding: "8px 10px",
                cursor: "pointer",
                background:
                  selectedLanguage === key ? "#e0e0e0" : "transparent",
              }}
              onClick={() => handleLanguageChange(key)}
            >
              {languageMap[key].label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSelectComponent;
