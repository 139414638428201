import React from "react";
import Accordion from "react-bootstrap/Accordion";

const Faq = ({faq}) => {
  const accordionBodyStyle = {
    color: "var(--Text-Secondary, #525252)",
    fontVariantNumeric: "lining-nums tabular-nums",
    fontFamily: "'Noto Sans TC', sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px", // 150%
  };
  return (
    <React.Fragment>
      <div
        className="flex flex-col items-center gap-[40px] w-full py-[30px] px-[50px] md:py-[60px] md:px-[60px] lg:py-[120px] lg:px-[133px]"
        style={{
          background: "#FFF",
        }}
      >
        {/* Header */}
        <h1 className="testimonials-text1 text-black">FAQ</h1>
        {/* Accordion */}
        <Accordion defaultActiveKey="0" className="w-full max-w-[800px]">
          {faq.map((item, index) => (
            <Accordion.Item eventKey={index.toString()} key={index}>
              <Accordion.Header>{item.q}</Accordion.Header>
              <Accordion.Body style={accordionBodyStyle}>
                {item.a}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    </React.Fragment>
  );
};

export default Faq;
