import React from "react";

import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";

import highlight from "./images/Highlight.png";
import highlight2 from "./images/Highlight2.png";
import highlight3 from "./images/Highlight3.png";
import highlight4 from "./images/Highlight4.png";

const testimonialsData = [
  { id: 1, name: "Highlight", img: highlight },
  { id: 2, name: "Highlight2", img: highlight },
  { id: 3, name: "Highlight3", img: highlight2 },
  { id: 4, name: "Highlight4", img: highlight3 },
  { id: 5, name: "Highlight5", img: highlight3 },
  { id: 6, name: "Highlight6", img: highlight4 },
];

const Testimonials = ({title}) => {
  return (
    <React.Fragment>
      <div
        className="flex-grow px-0 relative z-0"
        style={{
          background:
            "var(--InJOY-Gradient, linear-gradient(90deg, #0060E5 0%, #1A65E8 7.4%, #476EEE 21.8%, #5972F0 29.05%, #7871D8 39.19%, #B170AD 59.98%, #DC6F8D 77.79%, #F56E7A 91.7%, #FF6E72 100%))",
        }}
      >
        <div
          className="absolute inset-0"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/img/background/injoy-banner-background.svg)`,
            opacity: 0.1,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            zIndex: -1,
          }}
        ></div>
        <div className="w-full py-[30px] px-[50px] md:py-[60px] md:px-[60px] lg:py-[120px] lg:px-[133px]">
          <h1 className="testimonials-text1">
            {title}
          </h1>
          <div className="flex items-center justify-center pt-[60px]">
          {" "}
          {/* Removed md:h-screen */}
          <div
            className="relative w-[1000px] bg-gray-900 rounded-[24px]"
            style={{
              border: "4px solid transparent", // Ensures the border is visible
              borderRadius: "24px",
              background: `
              linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), 
              linear-gradient(90deg, #0060E5, #1A65E8, #476EEE, #5972F0, #7871D8, #B170AD, #DC6F8D, #F56E7A, #FF6E72)
        `,
              backgroundOrigin: "border-box",
              backgroundClip: "padding-box, border-box", // Gradient appears on the border
              aspectRatio: "16 / 9", // Maintains 16:9 aspect ratio
            }}
          >
            <video
              className="absolute top-0 left-0 w-full h-full rounded-[24px]"
              src={`${process.env.PUBLIC_URL}/img/injoy/testimonials/highlight.mp4`}
              autoPlay
              muted
              loop
              playsInline
              controls
            
            />
          </div>
        </div>
        
      </div>
        {/* <div
          className="absolute inset-0"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/img/background/injoy-banner-background.svg)`,
            opacity: 0.1,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            zIndex: -1,
          }}
        ></div>
        <div className="flex flex-col items-center gap-[60px] w-full py-[30px] px-[50px] md:py-[60px] md:px-[60px] lg:py-[120px] lg:px-[133px]">
          <h1 className="testimonials-text1">
            {title}
          </h1>
          <div className="relative w-[310px] sm:w-[500px] md:w-[600px] lg:w-[900px] bg-gray-900 rounded-[24px] overflow-hidden">
            <Carousel>
              {testimonialsData.map((item, index) => (
                <Carousel.Item key={index}>
                  <div className="absolute top-0 w-full h-full bg-black opacity-50 rounded-[24px]"></div>
                  <div className="w-full h-[300px] md:h-[500px]">
                    <img
                      className="w-full h-full rounded-[24px] object-cover "
                      src={item.img}
                      alt={`Slide ${index + 1}`}
                    />
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        </div> */}
      </div>
    </React.Fragment>
  );
};

export default Testimonials;
