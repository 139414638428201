import PricingComponent from "./pricing-component"

const Pricing = ({pricing}) => {
    return(
        <div>
            <div
            className="flex flex-col items-center gap-[40px] w-full py-[30px] px-[50px] md:py-[60px] md:px-[60px] lg:py-[120px] lg:px-[133px]"
             >
                <h1 className="testimonials-text1 text-black">Pricing</h1>
                <div className="grid gap-[24px] md:grid-cols-1 lg:grid-cols-3 justify-items-center">
                    {pricing.map((p)=>{
                        return(
                            <PricingComponent title={p.title} price={p.price} infos={p.detail}/>
                        )
                    })}
                </div>
             </div>
        </div>
    )
}

export default Pricing