import React from "react";

const Feature1 = ({title1, title2, title3, header, subtitle, subsubtitle, bullet1, bullet2, bullet3, card1Title, card1Text, card2Title, card2Text, card3Title, card3Text}) => {
  return (
    <React.Fragment>
      {/* Blue Background (Container) */}
      <div
        className="flex w-full flex-col items-center gap-[80px] py-[30px] px-[50px] md:py-[60px] md:px-[60px] lg:py-[120px] lg:px-[133px]"
        style={{
          background:
            "linear-gradient(0deg, #E7F0FE 0%, #E7F0FE 100%), #E5F0FF",
        }}
      >
        {/* Header Text*/}
        <div className="text-center leading-10">
          <span className="feature-text1 sm:text-4xl mx-[10px]">{title1}</span>
          <br/>
          <span className="feature-text2 sm:text-4xl">
            {title2}
          </span>
          <br/>
          <span className="feature-text1 sm:text-4xl">{title3}</span>
        </div>
        {/* Container for Blue Box & Explanations  */}
        <div className="flex flex-col gap-[30px] w-full lg:flex-row lg:items-start items-center">
          {/* Blue Box & Button */}
          <div className="flex flex-col w-[100%] md:w-[65%] grow">
            {/* (Blue Box) */}
            <div
              className="flex shrink-0  justify-center items-center relative " // Change screen size
              style={{
                borderRadius: "20px",
                background:
                  " linear-gradient(0deg, #C6D8F1 0%, #C6D8F1 100%), linear-gradient(0deg, #004BB2 0%, #004BB2 100%), linear-gradient(90deg, #FF6E72 0%, #F56E7A 8.3%, #DC6F8D 22.21%, #B170AD 40.02%, #7871D8 60.81%, #5972F0 70.95%, #476EEE 78.2%, #1A65E8 92.6%, #0060E5 100%)",
              }}
            >
              <img src={`${process.env.PUBLIC_URL}/img/injoy/feature1/feature1_photo.png`} className="p-[30px]"/>
            </div>
          </div>
          {/* Container for Content */}
          <div className="flex flex-col flex-grow pl-[20px] ">
            {/* Rounded Text & Header (AI) Container*/}
            <div className="flex flex-col items-start ">
              {/* Rounded Text */}
              <div
                className="flex p-[8px_16px] justify-center items-center mb-[20px]"
                style={{
                  borderRadius: "20px",
                  background: "#FFF",
                }}
              >
                <h1 className="feature1-rounded-text">{header}</h1>
              </div>
              <h1 className="feature1-text3 break-words">
                {subtitle}
              </h1>
            </div>
            {/* Explanations */}
            <h1 className="feature1-text4 break-words pt-[10px]">
              {subsubtitle}
            </h1>
            {/* Bullet Points */}
            <div className="flex flex-col pt-[10px] leading-relaxed">
              <ul className="space-y-2">
                <li className="feature1-text4 break-words">
                  &#x2022; {bullet1}
                </li>
                <li className="feature1-text4 opacity-40 break-words">
                  &#x2022; {bullet2}
                </li>
                <li className="feature1-text4 opacity-40 break-words">
                  &#x2022; {bullet3}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="grid gap-[24px] md:grid-cols-1 lg:grid-cols-3 justify-items-center">
          {
            [
              {"title": card1Title, "text": card1Text, photo:"Box1.png"},
              {"title": card2Title, "text": card2Text, photo:"Box2.png"},
              {"title": card3Title, "text": card3Text, photo:"Box3.png"},
            ].map((card, index) => (
              <div 
                key={index} 
                className="w-full px-[24px] pt-[24px] pb-[48px] gap-[24px] bg-white rounded-[20px] flex flex-col text-center shadow-lg"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/img/injoy/feature1/${card.photo}`}
                  className="w-full rounded-md"
                  alt={card.photo}
                />
                <div className="text-[24px] font-bold">
                  {card.title}
                </div>
                <p className="text-gray-600">
                  {card.text}
                </p>
              </div>
            ))
          }
        </div>
      </div>
    </React.Fragment>
  );
};

export default Feature1;
